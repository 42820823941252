<template>
  <section class="head">
    <div class="content">
      <div class="info" v-if="fieldList.length">
        <div class="title">
          <div class="bottom-boder">
            <span class="name">
              <OpenData
                type="userName"
                :openid="detailData.lecturerName"
                v-if="detailData.lecturerType == 1"
              /><template v-else>{{ detailData.lecturerName }}</template>
            </span>
            <span class="type"
              >（{{
                detailData.lecturerType == 1
                  ? $t("teacher.inside")
                  : $t("teacher.external")
              }}）</span
            >
          </div>
          <div
            class="expertTitle"
            v-if="defaultFieldObj.expertTitle.dataDisplay == 1"
          >
            <template
              v-if="detailData.expertTitle && detailData.expertTitle != ''"
            >
              <span
                v-for="(item, index) in detailData.expertTitle.split(',')"
                :key="index"
                >{{ item }}</span
              >
            </template>
          </div>
        </div>
        <div class="rows" :class="{ all: infoOpen }">
          <!-- 所属部门 -->
          <div class="item">
            <div class="ellipsis">
              <span class="label"
                >{{
                  detailData.lecturerType == 1
                    ? $t("teacher.department")
                    : $t("teacher.unit")
                }}：
              </span>
              <a-popover
                :autoAdjustOverflow="false"
                v-if="detailData.departmentName"
              >
                <template #content>
                  <div class="popover-text">
                    <OpenData
                      type="departmentName"
                      :openid="detailData.departmentName"
                    />
                  </div>
                </template>
                <OpenData
                  type="departmentName"
                  :openid="detailData.departmentName"
                />
              </a-popover>
              <template v-else>{{ $t("Home.NoContent") }}</template>
            </div>
          </div>
          <!-- 讲师简介 -->
          <div class="item" v-if="defaultFieldObj.intro.dataDisplay == 1">
            <div class="ellipsis">
              <span class="label">{{ $t("lecturer.lecturer_profile") }}：</span>
              <a-popover :autoAdjustOverflow="false" v-if="detailData.intro">
                <template #content>
                  <div class="popover-text">{{ detailData.intro }}</div>
                </template>
                {{ detailData.intro }}
              </a-popover>
              <template v-else>{{ $t("Home.NoContent") }}</template>
            </div>
          </div>
          <template v-for="item in fieldList" :key="item.fieldKey">
            <template
              v-if="
                item.defaultField == 2 &&
                item.dataDisplay == 1 &&
                item.fieldKey != 'custom_isDangerousTeacher'
              "
            >
              <div class="item" v-if="[1, 6].includes(item.fieldType)">
                <div class="ellipsis">
                  <span class="label">{{ item.fieldName }}：</span>
                  <a-popover
                    :autoAdjustOverflow="false"
                    v-if="detailData[item.fieldKey]"
                  >
                    <template #content>
                      <div class="popover-text">
                        {{ detailData[item.fieldKey] }}
                      </div>
                    </template>
                    {{ detailData[item.fieldKey] }}
                  </a-popover>
                  <template v-else>{{ $t("Home.NoContent") }}</template>
                </div>
              </div>
              <div class="item" v-else-if="[4, 5, 7].includes(item.fieldType)">
                <div class="ellipsis">
                  <span class="label">{{ item.fieldName }}：</span>
                  {{ detailData[item.fieldKey] }}
                </div>
              </div>
              <div class="item" v-else-if="[2, 8].includes(item.fieldType)">
                <div class="ellipsis">
                  <span class="label">{{ item.fieldName }}：</span>
                  <template v-if="detailData[item.fieldKey]">
                    <template v-if="Array.isArray(detailData[item.fieldKey])">
                      {{
                        detailData[item.fieldKey].map((o) => o.label).join(",")
                      }}
                    </template>
                    <template v-else>
                      {{ detailData[item.fieldKey].label }}
                    </template>
                  </template>
                </div>
              </div>
            </template>
          </template>
          <div class="shrink" v-if="extendFieldsLength > 8">
            <div class="shrink-btn" v-if="!infoOpen" @click="infoOpen = true">
              <DownOutlined /> {{ $t("XB_Home_SeeMore") }}
            </div>
            <div class="shrink-btn" v-else @click="infoOpen = false">
              <UpOutlined /> {{ $t("CM_Retract") }}
            </div>
          </div>
        </div>
      </div>
      <div class="img-bg">
        <img
          class="portrait"
          :src="detailData.portrait"
          alt="portrait"
          v-if="detailData.portrait"
        />
        <img
          class="portrait"
          src="@/assets/image/teacher_avatar.png"
          alt="portrait"
          v-else
        />
        <div class="level">
          <img :src="detailData.levelPicture" alt="" />
          <a-popover trigger="hover">
            <template #content>
              <div class="popover-content">
                {{ detailData.levelName }}
              </div>
            </template>
            <span>{{ detailData.levelName }}</span>
          </a-popover>
        </div>
      </div>
    </div>
  </section>

  <section class="tabs-wrap">
    <div class="tabs">
      <div id="pageTop"></div>
      <a-tabs v-model:activeKey="activeKey" size="large">
        <!-- 在线课程 -->
        <a-tab-pane key="1" :tab="$t('lecturer.online_courses')">
          <div v-if="courseList.length">
            <div
              class="onlineCourse pointer"
              v-for="item in courseList"
              :key="item.courseId"
              @click="clickJump(item)"
            >
              <div class="cover">
                <img :src="item.coverImg" alt="" />
                <div class="star">
                  <a-rate v-model:value="item.starScore" disabled />
                  <span class="star-num">
                    {{ item.starScore.toFixed(1) }}
                  </span>
                </div>
              </div>
              <div class="info">
                <div class="name">{{ item.courseName }}</div>
                <div class="other">
                  <div class="times">
                    <!-- 课程时长 -->
                    {{ $t("lecturer.course_duration") }}：{{
                      formatSeconds(item.coursePeriod)
                    }}
                  </div>
                  <div class="viewer">
                    <EyeOutlined />
                    {{ item.learnUsers }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 分页 -->
            <div class="page-Wrap">
              <a-pagination
                show-quick-jumper
                :show-total="(total) => $t('XB_InTotal', [pageTotalCourse])"
                :defaultPageSize="15"
                v-model:current="currentPageCourse"
                :total="pageTotalCourse"
                @change="courseListChange"
              >
              </a-pagination>
            </div>
          </div>
          <a-empty v-else style="padding: 50px 0" />
        </a-tab-pane>
        <!-- 线下培训 -->
        <a-tab-pane key="2" :tab="$t('lecturer.offline_training')">
          <div v-if="offlineTraining.length">
            <div
              class="onlineCourse"
              v-for="item in offlineTraining"
              :key="item.honorId"
            >
              <div class="cover">
                <img :src="item.coverImg" alt="cover" />
                <div class="learner">
                  <UserOutlined class="icon" />
                  {{ item.trainStudy ? item.trainStudy.length : 0 }}
                </div>
              </div>
              <div class="info">
                <div class="name">{{ item.courseName }}</div>
                <div class="date">
                  <ClockCircleOutlined class="icon" />
                  {{
                    `${item.trainTime[0].slice(
                      0,
                      16
                    )} ~ ${item.trainTime[1].slice(0, 16)}`
                  }}
                </div>
                <div class="address">
                  <img
                    class="icon"
                    src="@/assets/image/mine/addr.png"
                    alt="addr"
                  />
                  <a-popover
                    :autoAdjustOverflow="false"
                    :content="item.address"
                  >
                    <span>{{ item.address }}</span>
                  </a-popover>
                </div>
              </div>
            </div>
            <!-- 分页 -->
            <div class="page-Wrap">
              <a-pagination
                show-quick-jumper
                :show-total="(total) => $t('XB_InTotal', [pageTotalTrain])"
                :defaultPageSize="15"
                v-model:current="currentPageTrain"
                :total="pageTotalTrain"
                @change="offlineTrainingChange"
              >
              </a-pagination>
            </div>
          </div>
          <a-empty v-else style="padding: 50px 0" />
        </a-tab-pane>
        <!-- 讲师荣誉 -->
        <a-tab-pane key="3" :tab="$t('lecturer.honor')">
          <div v-if="LecturerHonor.length">
            <div class="honor">
              <div
                class="item"
                v-for="item in LecturerHonor"
                :key="item.honorId"
              >
                <div class="cover">
                  <a-image :src="item.picture" width="100%" :height="204" />
                  <div class="intro" v-if="item.description">
                    <div class="mask">
                      <span>{{ $t("teacher.honor_description") }}：</span
                      >{{ item.description }}
                    </div>
                  </div>
                </div>
                <div class="text">
                  <div class="name">{{ item.honorName }}</div>
                  <div class="time" v-if="item.endTime">
                    {{ $t("validity_period") }}：
                    {{ dateFormat(item.startTime, "YYYY-MM-DD") }} ~
                    {{ dateFormat(item.endTime, "YYYY-MM-DD") }}
                  </div>
                  <div class="time" v-else>
                    {{ $t("validity_period") }}：{{ $t("permanent") }}
                  </div>
                </div>
              </div>
              <!-- 分页 -->
              <div class="page-Wrap">
                <a-pagination
                  show-quick-jumper
                  :show-total="(total) => $t('XB_InTotal', [pageTotalHonor])"
                  :defaultPageSize="15"
                  v-model:current="currentPageHonor"
                  :total="pageTotalHonor"
                  @change="LecturerHonorChange"
                >
                </a-pagination>
              </div>
            </div>
          </div>
          <a-empty v-else style="padding: 50px 0" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </section>
  <CertificatePreview ref="CertificatePreviewRef" source="lecturerCenter" />
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { fieldConfigList } from "@/api/other";
import {
  lecturerDetail,
  lecturerCourse,
  lecturerLocalCourse,
  lecturerHonor,
} from "@/api/lecturer";
import { formatSeconds, handelSemicolonWrap, dateFormat } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";
import CertificatePreview from "@/components/new/CertificatePreview.vue";
export default {
  components: {
    OpenData,
    CertificatePreview,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const id = route.query.id;
    const state = reactive({
      fieldList: [],
      extendFields: {},
      extendFieldsLength: 0,
      defaultFieldObj: {},
      infoOpen: false,
    });

    const detailData = ref({}); // 基本信息
    // 获取基本信息
    const getLecturer = () => {
      lecturerDetail(id).then((res) => {
        if (res.ret === 0) {
          let d = res.data;
          d.fields = d.fields || [];
          d.fields.map((item) => {
            d[item.fieldKey] = item.fieldValue;
          });
          Object.keys(d).forEach((key) => {
            if (state.extendFields[key]) {
              let item = state.extendFields[key];
              if (item.fieldType == 2 || item.fieldType == 8) {
                if (d[key]) d[key] = JSON.parse(d[key]);
              }
              if (item.fieldType == 4) {
                if (d[key]) d[key] = Number(d[key]);
              }
            }
          });
          if (res.data.gender === 0) {
            res.data.gender = "--";
          } else if (res.data.gender === 1) {
            res.data.gender = "男";
          } else if (res.data.gender === 2) {
            res.data.gender = "女";
          }
          let year = String(res.data.birthday).slice(0, 4) + "年";
          let month = String(res.data.birthday).slice(4, 6) + "月";
          let day = String(res.data.birthday).slice(7, 9) + "日";
          res.data.birthday = `${year}${month}${day}`;
          detailData.value = res.data || [];
        } else {
          router.replace({
            path: `/error`,
            query: {
              title: $t("lecturer.not_exist"),
              path: "/lecturer",
              name: $t("lecturer_center"),
            },
          });
        }
      });
    };

    fieldConfigList(7).then((res) => {
      state.extendFieldsLength = 0;
      let d = res.data || [];
      let obj = {};
      d.forEach((item) => {
        if (item.defaultField == 2) {
          if (item.fieldType == 2) {
            item.fieldOptions.map((opt) => {
              opt.label = opt.optionValue;
              opt.value = opt.optionKey;
            });
          }
          if (
            item.dataDisplay == 1 &&
            item.fieldKey != "custom_isDangerousTeacher"
          ) {
            state.extendFieldsLength++;
          }
          state.extendFields[item.fieldKey] = item;
        } else {
          obj[item.fieldKey] = item;
        }
      });
      state.defaultFieldObj = obj;
      state.fieldList = d;
      getLecturer();
    });

    const courseList = ref({}), // 在线课程
      pageTotalCourse = ref(1), // 在线课程总数
      currentPageCourse = ref(1); // 当前页数
    // 获取讲师在线课程
    const getCourseList = (scroll = false) => {
        lecturerCourse({
          courseType: 1,
          id: id,
          page: currentPageCourse.value,
          pageSize: 15,
          sort: 0,
        }).then((res) => {
          if (res.ret == 0) {
            courseList.value = res.data.list || [];
            pageTotalCourse.value = res.data.totals;
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      courseListChange = (n) => {
        currentPageCourse.value = n;
        getCourseList(true);
      };
    getCourseList();

    const offlineTraining = ref({}), // 线下培训
      pageTotalTrain = ref(1), // 线下培训总数
      currentPageTrain = ref(1); // 当前页数
    // 获取讲师线下培训
    const getOfflineTraining = (scroll = false) => {
        lecturerLocalCourse({
          id: id,
          page: currentPageTrain.value,
          pageSize: 15,
          sort: 0,
        }).then((res) => {
          if (res.ret == 0) {
            offlineTraining.value = res.data.list || [];
            pageTotalTrain.value = res.data.totals;
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      offlineTrainingChange = (n) => {
        currentPageTrain.value = n;
        getOfflineTraining(true);
      };
    getOfflineTraining();

    const LecturerHonor = ref({}), // 讲师荣誉
      pageTotalHonor = ref(1), // 讲师荣誉总数
      currentPageHonor = ref(1); // 当前页数
    // 获取讲师荣誉
    const getLecturerHonor = (scroll = false) => {
        lecturerHonor({
          id: id,
          page: currentPageHonor.value,
          pageSize: 15,
          sort: 0,
        }).then((res) => {
          if (res.ret == 0) {
            LecturerHonor.value = res.data.list || [];
            pageTotalHonor.value = res.data.totals;
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      LecturerHonorChange = (n) => {
        currentPageHonor.value = n;
        getLecturerHonor(true);
      };
    getLecturerHonor();

    const clickJump = (item) => {
      let urlStr = "/course/center?id=" + item.courseId;
      if (store.getters.companyInfo.useNewWindow == 1) {
        window.open(urlStr + "&ddtab=true");
      } else {
        window.location.href = urlStr;
      }
    };

    return {
      formatSeconds,
      dateFormat,
      ...toRefs(state),
      activeKey: ref("1"),
      detailData,
      courseList,
      pageTotalCourse,
      currentPageCourse,
      courseListChange,
      offlineTraining,
      pageTotalTrain,
      currentPageTrain,
      offlineTrainingChange,
      LecturerHonor,
      pageTotalHonor,
      currentPageHonor,
      LecturerHonorChange,
      clickJump,
    };
  },
};
</script>

<style lang="less" scoped>
.head {
  padding: 24px 0 20px;
  background: #fafafa;
  .content {
    .mixinWrap();
    .mixinFlex(space-between);
    // height: 394px;
    padding: 24px 24px 16px 24px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
    .img-bg {
      width: 259px;
      height: 346px;
      background: #fff;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      .portrait {
        width: 100%;
        height: 100%;
      }
      .level {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 48px;
        background-color: rgba(255, 255, 255, 0.8);
        color: #666;
        font-size: 14px;
        .mixinFlex(center, center);
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        span {
          .mixinEllipsis(28px);
        }
      }
    }

    .info {
      width: 845px;
      // height: 346px;
      .title {
        .mixinFlex();
        .bottom-boder {
          border-bottom: 1px solid #ccc;
          height: 45px;
          margin-right: 24px;
          flex: none;
          .name {
            color: #333;
            font-size: 28px;
            font-weight: 600;
          }
          .type {
            color: #666;
            font-size: 14px;
          }
        }
        .expertTitle {
          padding-top: 11px;
          .mixinEllipsis(32px);
          span {
            color: #666;
            font-size: 16px;
            padding-right: 24px;
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
      .rows {
        margin-top: 28px;
        padding-bottom: 16px;
        height: 301px;
        min-height: 301px;
        overflow: hidden;
        background-color: #fff;
        position: relative;
        &.all {
          height: auto;
        }
        .shrink {
          text-align: center;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #fff;
          &-btn {
            display: inline-block;
            color: @color-theme;
            cursor: pointer;
            user-select: none;
            padding: 0 4px;
            height: 16px;
            line-height: 16px;
            font-size: 12px;
          }
        }
        .item {
          width: 410px;
          height: 44px;
          display: inline-block;
          margin-bottom: 14px;
          margin-right: 24px;
          color: #666;
          font-size: 16px;
          word-break: break-all;
          &:nth-child(2n) {
            margin-right: 0;
          }
          .ellipsis {
            .mixinEllipsis(44px, 2);
          }
          .label {
            color: #333;
            font-weight: 600;
          }
          &.lang {
            width: 100%;
          }
        }
      }
    }
  }
}

.tabs-wrap {
  padding-bottom: 40px;
  background: #fafafa;
  .tabs {
    width: 1200px;
    margin: 0 auto;
    padding: 32px;
    border-radius: 8px;
    background-color: #fff;
    ::v-deep(.ant-tabs-bar) {
      margin-bottom: 22px;
    }
    ::v-deep(.ant-tabs-ink-bar) {
      height: 4px;
      border-radius: 13px;
    }
    .onlineCourse {
      float: left;
      width: 365px;
      margin: 0 20px 20px 0;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(148, 148, 148, 0.1);
      transition: all 0.3s ease;
      .cover {
        width: 100%;
        height: 204px;
        border-radius: 8px 8px 0 0;
        overflow: hidden;
        position: relative;
        .star {
          position: absolute;
          height: 44px;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 16px 12px 0;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0) 100%
          );
          ::v-deep(.ant-rate) {
            color: #ffc805;
            font-size: 14px;
            height: 20px;
            .ant-rate-star-second,
            .ant-rate-star {
              height: 20px;
            }
            .ant-rate-star:not(:last-child) {
              margin-right: 4px;
            }
          }
          .star-num {
            padding-left: 4px;
            vertical-align: -1px;
            color: #ffc805;
            font-size: 14px;
          }
        }
        .learner {
          position: absolute;
          height: 28px;
          top: 8px;
          left: 8px;
          border-radius: 4px;
          background-color: rgba(255, 255, 255, 0.4);
          color: #666;
          font-size: 14px;
          line-height: 20px;
          padding: 4px 10px;
        }
      }
      .info {
        padding: 12px;
        color: #666;
        .name {
          .mixinEllipsis(32px);
          font-size: 16px;
          font-weight: 600;
          color: #333;
        }
        .other {
          .mixinFlex(space-between, center);
          margin-top: 4px;
          font-size: 14px;
          color: #666;
        }
        .icon {
          width: 16px;
          height: 16px;
          font-size: 14px;
          margin-right: 8px;
        }
        .date {
          margin-top: 4px;
          color: #666;
          font-size: 16px;
          .mixinFlex(flex-satrt, center);
        }
        .address {
          margin-top: 12px;
          color: #666;
          font-size: 16px;
          .mixinFlex(flex-satrt, center);
          span {
            .mixinEllipsis(25px);
          }
        }
      }
      &.pointer {
        cursor: pointer;
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.25);
        }
      }
      &:nth-child(3n) {
        margin-right: 0px;
      }
    }
    .page-Wrap {
      clear: both;
    }
    .honor {
      .item {
        float: left;
        width: 365px;
        margin: 0 20px 20px 0;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba(148, 148, 148, 0.1);
        transition: all 0.3s ease;
        .cover {
          width: 100%;
          height: 204px;
          border-radius: 8px 8px 0 0;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          .mixinFlex(center; center);
          background-image: url("~@/assets/image/mine/certificate_cover_bg.png");
          background-size: 100% 100%;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
          ::v-deep(.ant-image) {
            .mixinFlex(center; center);
            .ant-image-img {
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
            }
          }
          .intro {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: transparent;
            opacity: 0;
            transition: all 0.3s;
            pointer-events: none;
            .mask {
              margin: 12px;
              .mixinEllipsis(180px, 7);
              color: transparent;
              font-size: 16px;
              line-height: 26px;
              span {
                font-weight: bold;
              }
            }
          }
        }
        .text {
          padding: 12px;
        }
        .name {
          .mixinEllipsis(32px);
          font-size: 16px;
          font-weight: 600;
          color: #333;
        }
        .time {
          font-size: 14px;
          line-height: 20px;
          color: #666;
          margin-top: 4px;
        }
        &:nth-child(3n) {
          margin-right: 0px;
        }
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.25);
          .intro {
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 1;
            .mask {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.popover-text {
  max-width: 632px;
  max-height: 250px;
  overflow-x: auto;
  color: #666;
  font-size: 14px;
  white-space: pre-line;
  margin-right: -10px;
  padding-right: 10px;
}
img {
  width: 100%;
  height: 100%;
}
</style>
